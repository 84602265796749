import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import AltSection from '../components/columnAlternating'

library.add(fat)

const Partners = ({ data, location }) => {
  const post = data.markdownRemark
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : "all"  

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post?.frontmatter?.image?.childImageSharp?.gatsbyImageData?.images ?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>

            <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.openingpara,
                  }}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>             
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.tech &&
          <section className="bg-light-blue">
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <h2 className="font-alt font-w-700 letter-spacing-1 pb-5 title-xs-medium title-large" >
                      Our technology partners 
                      <span id="technology-partners" className="anchor">
                        &nbsp;
                      </span>                    
                    </h2>
                  </MDBCol>
               </MDBRow>
            </MDBContainer>
              <MDBContainer>
                <div className="about-overview pt-5">
                  {post.frontmatter.tech.map((techpartners, index) => {
                    return (
                      <AltSection
                        key={index}
                        textCol={'9'}
                        imageCol={'3'}
                        title={techpartners.title}
                        description={techpartners.description}
                        image={techpartners.image.childImageSharp.gatsbyImageData}
                        imageClass="rounded"
                        alt={techpartners.alttext}
                        placement={techpartners.placement}
                      />
                    )
                  })}
                </div>
              </MDBContainer>
          </section>
        } 

      </main>
    </Layout>
  )
}
export default Partners 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "Partners" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        ctatext
        ctaslug
        openingpara 
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        tech {
          title
          placement
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 90)
            }
          }
          alttext
        }              
      }
      html
    }
  }
`